import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/auth";
import "./style.css";

export default function Login() {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");

  const { signIn, loadingAuth } = useContext(AuthContext);

  const loginInputRef = useRef(null);

  async function handleLogar(e) {
    e.preventDefault();

    if (login !== "" && senha !== "") {
      await signIn(login, senha);
    } else {
      toast("Preencha todos os campos", {
        theme: "dark",
      });
    }
  }

  useEffect(() => {
    document.title = "Área do Cliente";
  }, []);

  useEffect(() => {
    document.title = "Área do Cliente";
    // Defina o foco no campo de login
    if (loginInputRef.current) {
      loginInputRef.current.focus();
    }
  }, []);

  return (
    <div className="container_login">
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="box">
        <div className="square" style={{ "--var1": 0 }}></div>
        <div className="square" style={{ "--var1": 1 }}></div>
        <div className="square" style={{ "--var1": 2 }}></div>
        <div className="square" style={{ "--var1": 3 }}></div>
        <div className="square" style={{ "--var1": 4 }}></div>
        <div className="login_area">
          <div className="form">
            <h2>Entrar</h2>
            <form onSubmit={handleLogar}>
              <div className="input_box">
                <input
                  type="text"
                  placeholder="Login"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  ref={loginInputRef}
                />
              </div>
              <div className="input_box">
                <input
                  type="password"
                  placeholder="Senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
              </div>

              <div className="input_box">
                <input
                  type="submit"
                  value={"LOGIN"}
                  style={{ maxWidth: 120 }}
                />
              </div>
              <p className="forget">
                Esqueceu sua senha? <Link to="/app/newpass">Clique Aqui</Link>
              </p>
              <p className="forget mt-2">
                Canais de suporte ref. acesso: <br />
                <a
                  href="mailto:infra@customax.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Email
                </a>{" "}
                |{" "}
                <a
                  href="https://wa.me/5547992822319"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>{" "}
                | <a href="skype:customax.alan.leopold?chat">Skype</a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
