import React, { useState } from "react";
import { MdPassword } from "react-icons/md";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Cabecalho from "../../components/Cabecalho";
import Menu from "../../components/Menu";
import CmxButton from "../../components/ui/Button";
import CmxInput from "../../components/ui/Input";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

export default function AlterarSenha() {
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senhaNova, setSenhaNova] = useState("");
  const [senhaNovaConfirma, setSenhaNovaConfirma] = useState("");

  const [salvando, setSalvando] = useState(false);

  async function handleSave(e) {
    e.preventDefault();
    setSalvando(true);

    try {
      const data = {
        senha_atual: senhaAtual,
        senha_nova: senhaNova,
      };

      if (senhaAtual && senhaNova && senhaNovaConfirma) {
        if (senhaNova !== senhaNovaConfirma) {
          Swal.fire({
            title: "Alerta!",
            text: "Senhas não conferem!",
            icon: "warning",
          });
          setSalvando(false);
          return;
        }

        await api
          .put("/usuario/changepass", {
            senha_atual: senhaAtual,
            senha_nova: senhaNova,
          })
          .then(() => {
            console.log("SUCESS");
            setSenhaAtual("");
            setSenhaNova("");
            setSenhaNovaConfirma("");

            toast.success("Senha Alterada com sucesso!");
          })
          .catch((err) => {
            console.log("ERROR");
            console.log(err);
            console.log(err.response.data);
            toast.error(`${err.response?.data.error}`);
          });
      } else {
        toast.warning(`Por favor, preencha todos os campos obrigatórios.`);
      }
    } catch (error) {
      handleError(error, "Erro ao salvar senha nova");
    }

    setSalvando(false);
  }

  return (
    <div>
      <Menu />
      <div className="content">
        <Cabecalho descricao="Alterar Senha">
          <MdPassword size={24} />
        </Cabecalho>
        <div className="content_area">
          <form action="form-profile">
            <div className="col">
              <label>Senha Atual *</label>
              <CmxInput
                type="password"
                autoFocus={true}
                obrigatorio={true}
                value={senhaAtual}
                onChange={(e) => setSenhaAtual(e.target.value)}
              />
            </div>

            <div className="col">
              <label>Nova Senha *</label>
              <CmxInput
                type="password"
                obrigatorio={true}
                value={senhaNova}
                onChange={(e) => setSenhaNova(e.target.value)}
              />
            </div>

            <div className="col">
              <label>Confirmar Nova Senha *</label>
              <CmxInput
                type="password"
                obrigatorio={true}
                value={senhaNovaConfirma}
                onChange={(e) => setSenhaNovaConfirma(e.target.value)}
              />
            </div>

            <CmxButton
              loading={salvando}
              style={{ marginLeft: "12px", marginTop: "12px" }}
              type="submit"
              onClick={handleSave}
            >
              Salvar
            </CmxButton>
          </form>
        </div>
      </div>
    </div>
  );
}
