export const SituacaoChamado = {
  Em_Aberto: 1,
  Em_Analise: 2,
  Elaborando_Orcamento: 3, //Antigo Em Avaliação
  Aguardando_Aprovacao: 4, //Antigo Em Orçamento
  Em_Progresso: 5,
  Baixado: 6,
  Encerrado: 7,
  Cancelado: 8,
};
export const DescEnum_ChamadoSit = {
  [SituacaoChamado.Em_Aberto]: "Em Aberto",
  [SituacaoChamado.Em_Analise]: "Em Análise",
  [SituacaoChamado.Elaborando_Orcamento]: "Elaborando Orçamento",
  [SituacaoChamado.Aguardando_Aprovacao]: "Aguardando Aprovação",
  [SituacaoChamado.Em_Progresso]: "Em Progresso",
  [SituacaoChamado.Baixado]: "Baixado",
  [SituacaoChamado.Encerrado]: "Encerrado",
  [SituacaoChamado.Cancelado]: "Cancelado",
};

export const TipoAcaoChamado = {
  Texto: 0,
  Anexo: 1,
};
export const DescEnum_TipoAcaoChamado = {
  [TipoAcaoChamado.Texto]: "Texto",
  [TipoAcaoChamado.Anexo]: "Anexo",
};

export const AcaoDe = {
  Cliente: 0,
  Customax: 1,
};
export const DescEnum_AcaoDe = {
  [AcaoDe.Cliente]: "Cliente",
  [AcaoDe.Customax]: "Customax",
};

export const TipoServicoChamado = {
  Duvida: 1,
  Erro: 2,
  Servico: 3,
};
export const DescEnum_TipoServicoChamado = {
  [TipoServicoChamado.Duvida]: "Dúvida",
  [TipoServicoChamado.Erro]: "Erro",
  [TipoServicoChamado.Servico]: "Serviço",
};

export const UrgenciaChamado = {
  Alta: 1,
  Media: 2,
  Baixa: 3,
};
export const DescEnum_UrgenciaChamado = {
  [UrgenciaChamado.Alta]: "Alta",
  [UrgenciaChamado.Media]: "Média",
  [UrgenciaChamado.Baixa]: "Serviço",
};

export const AreaChamado = {
  Comercial: 1,
  Financeiro: 2,
  Industrial: 3,
  Controladoria: 4,
};
export const DescEnum_AreaChamado = {
  [AreaChamado.Comercial]: "Comercial",
  [AreaChamado.Financeiro]: "Financeiro",
  [AreaChamado.Industrial]: "Industrial",
  [AreaChamado.Controladoria]: "Controladoria",
};

export const TipoNoticia = {
  Texto: 0,
  Imagem: 1,
};
export const DescEnum_TipoNoticia = {
  [TipoNoticia.Texto]: "Texto",
  [TipoNoticia.Imagem]: "Imagem",
};

export const OutroEnum = {
  Valor1: "valor1",
  Valor2: "valor2",
};

export const convertToArray = (object, descEnumObj) => {
  return Object.keys(object).map((key) => descEnumObj[object[key]]);
};

export function mapToIds(enumObj, descEnumObj, arrayOfStrings) {
  return arrayOfStrings.map((status) => {
    const key = Object.keys(enumObj).find(
      (key) => descEnumObj[enumObj[key]] === status
    );
    return enumObj[key];
  });
}

export const getOptionsFromEnum = (enumObj, descObj) => {
  return Object.keys(enumObj).map((key) => ({
    label: descObj[enumObj[key]],
    value: enumObj[key],
  }));
};
