import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import {
  FaFilePdf,
  FaFileExcel,
  FaFileWord,
  FaFileCsv,
  FaFileAudio,
  FaFileVideo,
  FaFile,
} from 'react-icons/fa';

import { Container, FileInfo, Preview } from './styles';

const getFileTypeIcon = (fileName) => {
  const lowerCaseFileName = fileName.toLowerCase();

  if (lowerCaseFileName.endsWith('.pdf')) {
    return <FaFilePdf />;
  } else if (lowerCaseFileName.endsWith('.txt')) {
    return <FiFileText />;
  } else if (
    lowerCaseFileName.endsWith('.doc') ||
    lowerCaseFileName.endsWith('.docx')
  ) {
    return <FaFileWord />;
  } else if (
    lowerCaseFileName.endsWith('.xls') ||
    lowerCaseFileName.endsWith('.xlsx')
  ) {
    return <FaFileExcel />;
  } else if (lowerCaseFileName.endsWith('.csv')) {
    return <FaFileCsv />;
  } else if (
    lowerCaseFileName.endsWith('.mp3') ||
    lowerCaseFileName.endsWith('.wav')
  ) {
    return <FaFileAudio />;
  } else if (
    lowerCaseFileName.endsWith('.mp4') ||
    lowerCaseFileName.endsWith('.avi')
  ) {
    return <FaFileVideo />;
  } else {
    return <FaFile />;
  }
};

function FileUploaderList({ files, onDelete }) {
  return (
    <Container>
      {files.map((uploadedFile) => (
        <li key={uploadedFile.id}>
          <FileInfo>
            <Preview src={uploadedFile.preview} alt='Anexo'>
              {!uploadedFile.name.endsWith('.png') &&
                !uploadedFile.name.endsWith('.jpg') &&
                !uploadedFile.name.endsWith('.jpeg') &&
                !uploadedFile.name.endsWith('.gif') &&
                getFileTypeIcon(uploadedFile.name, uploadedFile.preview)}
            </Preview>
            {/**/}
            <div>
              <strong>{uploadedFile.name}</strong>
              <span>
                {uploadedFile.ReadableSize}
                <button type='button' onClick={() => onDelete(uploadedFile.id)}>
                  Excluir
                </button>
              </span>
            </div>
          </FileInfo>
          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{ root: { width: 24 }, path: { stroke: '#3f51b5' } }}
                strokeWidth={9}
                value={uploadedFile.progress}
              />
            )}
            {uploadedFile.url && (
              <a
                href='https://img.freepik.com/vetores-gratis/conceito-de-web-design-apresentando-conteudo-em-paginas-da-web-composicao-do-layout-do-site-e-desenvolvimento-de-cores-ideia-de-tecnologia-de-computador-ilustracao-em-vetor-plana_613284-1991.jpg?size=626&ext=jpg'
                target='_blank'
                rel='noopener noreferrer'>
                <MdLink style={{ marginRight: 9 }} size={24} color='#222' />
              </a>
            )}
            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color='#78e5d5' />
            )}
            {uploadedFile.error && <MdError size={24} color='#e57878' />}
          </div>
        </li>
      ))}
    </Container>
  );
}

export default FileUploaderList;
