import React, { useContext } from 'react';
import { AuthContext } from '../context/auth';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';

function RoutesApp() {
  const { signed, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Carregando</div>;
  }
  return signed ? <AppRoutes /> : <AuthRoutes />;
}

export default RoutesApp;
