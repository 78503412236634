import classnames from "classnames";
import React from "react";
import { BiCheck } from "react-icons/bi";
import styles from "./styles.module.scss";

export default function CmxCheckBox({
  descricao,
  onCheckboxChange,
  isChecked,
  ...props
}) {
  // const [isChecked, setIsChecked] = useState(false);

  const checkboxItemClasses = classnames(styles.checkbox_item, {
    [styles.item_checked]: isChecked,
  });

  const checkboxIconClasses = classnames(styles.checkbox_icon, {
    [styles.icon_checked]: isChecked,
  });

  const handleKeyDown = (event) => {
    // Verifica se a tecla pressionada é a tecla de espaço (código 32)
    if (event.keyCode === 32) {
      // Impede o comportamento padrão (scrolling, etc.) ao pressionar a tecla de espaço
      event.preventDefault();

      toggleCheckbox();
    }
  };

  const toggleCheckbox = () => {
    // Chama a função para alterar o checkbox
    onCheckboxChange(!isChecked);
  };

  return (
    <div
      className={checkboxItemClasses}
      role="checkbox"
      aria-checked={isChecked}
      tabIndex={0}
      onClick={toggleCheckbox}
      onKeyDown={handleKeyDown}
      style={{ width: props.width, height: props.height }}
    >
      <span className={styles.checkbox_box}>
        <BiCheck size={24} className={checkboxIconClasses} />
      </span>
      <span
        style={{ fontSize: props.fontSize }}
        className={styles.checkbox_item_text}
      >
        {descricao}
      </span>
    </div>
  );
}
