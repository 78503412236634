import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "../Login/style.css";

export default function CadastrarNovaSenha() {
  const [userID, setUserID] = useState(null);
  const [login, setLogin] = useState(null);
  const [senha1, setSenha1] = useState("");
  const [senha2, setSenha2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Cadastrar Nova Senha";
  }, []);

  useEffect(() => {
    api
      .post("/usuario/tokenvalidation", {
        token: token,
      })
      .then((response) => {
        setUserID(response.data.id);
        setLogin(response.data.login);
      })
      .catch((err) => {
        // console.log(err);
        // console.log(err.response.data);
        setErrorMsg(`${err.response?.data.error}`);

        console.log(`${err.response?.data.error}`);
      });
  }, [token]);

  async function handleCadaster(e) {
    e.preventDefault();

    try {
      if (senha1 && senha2) {
        if (senha1 !== senha2) {
          Swal.fire({
            title: "Alerta!",
            text: "Senhas não conferem!",
            icon: "warning",
          });

          return;
        }

        await api
          .put("/usuario/resetpass", {
            user_id: userID,
            senha_nova: senha1,
            token: token,
          })
          .then(() => {
            toast.success("Senha Alterada com sucesso!");
          })
          .catch((err) => {
            console.log("ERROR");
            console.log(err);
            console.log(err.response.data);
            toast.error(`${err.response?.data.error}`);
            return;
          });
      } else {
        toast.warning(`Por favor, preencha todos os campos obrigatórios.`);
        return;
      }
    } catch (error) {
      handleError(error, "Erro ao cadastrar senha nova");
      return;
    }
    navigate("/");
  }

  async function handleToLogin(e) {
    e.preventDefault();
    navigate("/");
  }

  return (
    <div className="container_login">
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="box">
        <div className="square" style={{ "--var1": 0 }}></div>
        <div className="square" style={{ "--var1": 1 }}></div>
        <div className="square" style={{ "--var1": 2 }}></div>
        <div className="square" style={{ "--var1": 3 }}></div>
        <div className="square" style={{ "--var1": 4 }}></div>
        <div className="login_area">
          <div className="form">
            {errorMsg ? (
              <h2>{errorMsg}</h2>
            ) : (
              <>
                <h2>{login}</h2>
                <h2>Cadastre sua Nova Senha</h2>
                <form>
                  <div className="input_box">
                    <input
                      type="password"
                      placeholder="Informe sua nova senha"
                      value={senha1}
                      onChange={(e) => setSenha1(e.target.value)}
                    />
                  </div>

                  <div className="input_box">
                    <input
                      type="password"
                      placeholder="Repita sua nova senha"
                      value={senha2} // Aqui você provavelmente quer usar outra variável, como setNewPassword
                      onChange={(e) => setSenha2(e.target.value)} // E aqui também, como setNewPassword
                    />
                  </div>

                  <div className="input_box">
                    <input
                      type="submit"
                      value="Cadastrar Senha"
                      onClick={handleCadaster}
                    />
                    <br />
                    <input
                      type="submit"
                      value="Retornar"
                      onClick={handleToLogin}
                      style={{ marginTop: -6 }}
                    />
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
