import { Route, Routes } from "react-router-dom";

import AlterarSenha from "../pages/AlterarSenha";
import Home from "../pages/Home";
import NovaSolicitacao from "../pages/NovaSolicitacao";
import SolicitacaoCons from "../pages/SolicitacaoCons";
import SolicitacaoDet from "../pages/SolicitacaoDet";

import NotFoundPage from "../pages/NotFoundPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/app/chamado" element={<NovaSolicitacao />} />
      <Route path="/app/chamados" element={<SolicitacaoCons />} />
      <Route path="/app/chamados/:chamadoid" element={<SolicitacaoDet />} />
      <Route path="/app/change-pass" element={<AlterarSenha />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
