import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./style.css";

import logoCustomax from "../../assets/customax.svg";

import { FiHome, FiPlusCircle, FiShoppingBag } from "react-icons/fi";

export default function Menu() {
  return (
    <div className="sidebar">
      <div
        className="logo-customax"
        onClick={() => window.open("https://www.customax.com.br", "_blank")}
      >
        <img src={logoCustomax} alt="CUSTOMAX" />
      </div>

      {/* <div>
        <img className="imgem_menu" src={logoImg} alt="Foto Perfil" />
      </div> */}

      <Link to="/">
        <FiHome color="#fff" size={24} />
        <span>Página Inicial</span>
      </Link>
      <Link to="/app/chamado">
        <FiPlusCircle color="#fff" size={24} />
        <span>Novo Chamado</span>
      </Link>

      <Link to="/app/chamados">
        <FiShoppingBag color="#fff" size={24} />
        <span>Consulta de Chamados</span>
      </Link>

      <div className="additional-links">
        <a
          href="http://centraldeajuda.consistem.com.br/pages/viewpage.action?pageId=38209683"
          target="_blank"
          rel="noopener noreferrer"
        >
          Garantia de Customizações
        </a>
        <a
          href="https://customax.com.br/garantia"
          target="_blank"
          rel="noopener noreferrer"
        >
          Garantia de Produtos
        </a>
        <a
          href="https://customax.com.br/privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
      </div>

      <div className="social-media-icons">
        <a
          href="https://www.facebook.com/customaxtecnologia/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookF color="rgba(255, 255, 255, 0.5)" size={24} />
        </a>
        <a
          href="https://www.instagram.com/customaxtecnologia/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram color="rgba(255, 255, 255, 0.5)" size={24} />
        </a>
        <a
          href="https://www.linkedin.com/company/customaxtecnologia/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn color="rgba(255, 255, 255, 0.5)" size={24} />
        </a>
      </div>

      {/* <div className="divisor">
        <img src={divisor} alt="-------------------" />
      </div> */}
    </div>
  );
}
