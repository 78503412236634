import { addDays, isValid, parse } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "./style.css";

export default function CmxDate({
  obrigatorio,
  inputValue,
  setInputValue,
  ...props
}) {
  const [erro, setErro] = useState(false);
  // const [inputValue, setInputValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus(); // Foca no input quando o componente é montado
    }
  }, [props.autoFocus]);

  const handleBlur = () => {
    // Valide a data ao remover o foco
    validarData(inputValue);

    if (obrigatorio && !inputValue) {
      setErro(true);
    } else {
      setErro(false);
    }
  };

  const handleKeyPress = (e) => {
    if (
      (e.keyCode === 45 || e.keyCode === 43) &&
      !inputRef.current?.value.includes("h")
    ) {
      e.preventDefault();
    }

    if (
      (e.keyCode < 47 || e.keyCode > 57) && // numeros
      e.keyCode !== 104 && // h
      e.keyCode !== 45 && // -
      e.keyCode !== 43 // +
    ) {
      e.preventDefault();
    }

    var len = inputRef.current?.value.length;

    if (
      inputRef.current &&
      e.keyCode >= 47 &&
      e.keyCode <= 57 &&
      inputRef.current?.value.includes("h") &&
      !inputRef.current?.value.includes("+") &&
      !inputRef.current?.value.includes("-")
    ) {
      inputRef.current.value += "+";
    }

    if (
      (len > 0 && e.keyCode === 104) ||
      (inputRef.current?.value.includes("h") && len === 7) ||
      (inputRef.current?.value.includes("-") &&
        (e.keyCode === 45 || e.keyCode === 43)) ||
      (inputRef.current?.value.includes("+") &&
        (e.keyCode === 43 || e.keyCode === 45))
    ) {
      e.preventDefault();
    }

    if ((len !== 1 || len !== 3) && e.keyCode === 47) {
      e.preventDefault();
    }

    if (
      inputRef.current &&
      (len === 2 || len === 5) &&
      !inputRef.current?.value.includes("h") &&
      !inputRef.current?.value.includes("-") &&
      !inputRef.current?.value.includes("+")
    ) {
      inputRef.current.value += "/";
    }
  };

  useEffect(() => {
    inputRef.current?.addEventListener("keypress", handleKeyPress);

    return () => {
      inputRef.current?.removeEventListener("keypress", handleKeyPress);
    };
  }, [inputValue]);

  const validarData = (data) => {
    if (data && data !== "" && data.length > 0) {
      let dataAtual = new Date();
      let dia = dataAtual.getDate();
      let mes = dataAtual.getMonth() + 1;
      let ano = dataAtual.getFullYear();

      var diaFormatado = String(dia).padStart(2, "0");
      var mesFormatado = String(mes).padStart(2, "0");

      const formato = "dd/MM/yyyy";
      let dataNova = ``;

      if (data.includes("h")) {
        if (data === "h+") {
          dataAtual = addDays(dataAtual, 1);
        } else if (data === "h-") {
          dataAtual = addDays(dataAtual, -1);
        } else if (data.includes("h+")) {
          let spl = data.split("+");
          dataAtual = addDays(dataAtual, spl[1]);
        } else if (data.includes("h-")) {
          let spl = data.split("-");
          dataAtual = addDays(dataAtual, -spl[1]);
        }
        dia = dataAtual.getDate();
        mes = dataAtual.getMonth() + 1;
        ano = dataAtual.getFullYear();

        diaFormatado = String(dia).padStart(2, "0");
        mesFormatado = String(mes).padStart(2, "0");
        dataNova = `${diaFormatado}/${mesFormatado}/${ano}`;
      } else {
        data = data.replace(/\//g, ""); // remove as barras com regex
        if (data.length === 1) {
          dataNova = `0${data}/${mesFormatado}/${ano}`;
        } else if (data.length === 2) {
          dataNova = `${data}/${mesFormatado}/${ano}`;
        } else if (data.length === 4) {
          dia = data.substring(0, 2);
          mes = data.substring(2, 4);
          dataNova = `${dia}/${mes}/${ano}`;
        } else if (data.length === 6) {
          dia = data.substring(0, 2);
          mes = data.substring(2, 4);
          ano = data.substring(4, 6);
          dataNova = `${dia}/${mes}/20${ano}`;
        } else if (data.length === 8) {
          dia = data.substring(0, 2);
          mes = data.substring(2, 4);
          ano = data.substring(4, 8);
          dataNova = `${dia}/${mes}/${ano}`;
        }
      }

      var dataParse = parse(dataNova, formato, new Date());

      if (!isValid(dataParse)) {
        toast.warning("data inválida! " + dataNova);
        setInputValue("");
        inputRef.current.focus();
      } else {
        setInputValue(dataNova);
      }
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={`input  ${props.disabled ? "desabilitado" : ""}`}
        onBlur={handleBlur}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        maxLength={10}
        {...props}
      />
      {!props.disabled && erro && (
        <span className="errorMessage">Campo obrigatório!</span>
      )}
    </>
  );
}

export function CmxTime({ obrigatorio, inputValue, setInputValue, ...props }) {
  const [erro, setErro] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus(); // Foca no input quando o componente é montado
    }
  }, [props.autoFocus]);

  const handleBlur = () => {
    // Valide a hora ao remover o foco
    validarHora(inputValue);

    if (obrigatorio && !inputValue) {
      setErro(true);
    } else {
      setErro(false);
    }
  };

  const handleKeyPress = (e) => {
    if (
      e.keyCode < 47 ||
      e.keyCode > 57 // numeros
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    inputRef.current?.addEventListener("keypress", handleKeyPress);

    return () => {
      inputRef.current?.removeEventListener("keypress", handleKeyPress);
    };
  }, [inputValue]);

  const validarHora = (hora) => {
    if (hora && hora !== "" && hora.length > 0) {
      let horaNova = ``;

      hora = hora.replace(/:/g, ""); // remove os dois pontos com regex
      if (hora.length === 1) {
        horaNova = `00:0${hora}`;
      } else if (hora.length === 2) {
        if (parseInt(hora, 10) < 60) horaNova = `00:${hora}`;
        else if (parseInt(hora, 10) === 60) horaNova = `01:00`;
        else {
          toast.warning("hora inválida! " + hora);
          setInputValue("");
          inputRef.current.focus();
          return;
        }
      } else if (hora.length > 2) {
        let pos = hora.length - 2;
        let hr = hora.substring(0, pos);
        let min = hora.substring(pos);

        if (parseInt(min, 10) > 59) {
          toast.warning("hora inválida! " + hora);
          setInputValue("");
          inputRef.current.focus();
          return;
        }

        horaNova = `${hr}:${min}`;
      }

      setInputValue(horaNova);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={`input  ${props.disabled ? "desabilitado" : ""}`}
        onBlur={handleBlur}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        maxLength={10}
        {...props}
      />
      {!props.disabled && erro && (
        <span className="errorMessage">Campo obrigatório!</span>
      )}
    </>
  );
}
