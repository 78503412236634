import React, { useContext, useEffect, useState } from "react";
import { FaBook } from "react-icons/fa";
import Cabecalho from "../../components/Cabecalho";
import Filtros from "../../components/Filtros";
import GridChamados from "../../components/GridChamados";
import Menu from "../../components/Menu";
import { AuthContext } from "../../context/auth";
import {
  AreaChamado,
  DescEnum_AreaChamado,
  DescEnum_ChamadoSit,
  DescEnum_TipoServicoChamado,
  SituacaoChamado,
  TipoServicoChamado,
  convertToArray,
  mapToIds,
} from "../../enums/enums";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

export default function SolicitacaoCons() {
  const [loading, setLoading] = useState(false);
  const [chamados, setChamados] = useState([]);

  const [filters, setFilters] = useState({
    abertoDe: "01/07/2010",
    abertoAte: "31/12/2100",
    situacaoChamado: [
      DescEnum_ChamadoSit[SituacaoChamado.Em_Aberto],
      DescEnum_ChamadoSit[SituacaoChamado.Aguardando_Aprovacao],
      DescEnum_ChamadoSit[SituacaoChamado.Em_Progresso],
    ],
    tipoChamado: [
      DescEnum_TipoServicoChamado[TipoServicoChamado.Duvida],
      DescEnum_TipoServicoChamado[TipoServicoChamado.Erro],
      DescEnum_TipoServicoChamado[TipoServicoChamado.Servico],
    ],
    area: [
      DescEnum_AreaChamado[AreaChamado.Comercial],
      DescEnum_AreaChamado[AreaChamado.Financeiro],
      DescEnum_AreaChamado[AreaChamado.Industrial],
      DescEnum_AreaChamado[AreaChamado.Controladoria],
    ],
  });

  const situacoesChamado = convertToArray(SituacaoChamado, DescEnum_ChamadoSit);
  const tiposChamado = convertToArray(
    TipoServicoChamado,
    DescEnum_TipoServicoChamado
  );
  const areas = convertToArray(AreaChamado, DescEnum_AreaChamado);

  const { user } = useContext(AuthContext);

  useEffect(() => {
    carregarChamados();
  }, [filters]);

  async function carregarChamados() {
    setLoading(true);
    const { abertoDe, abertoAte, tipoChamado, situacaoChamado, area } = filters;

    const situacaoNumeros = mapToIds(
      SituacaoChamado,
      DescEnum_ChamadoSit,
      situacaoChamado
    );
    const tipoServicoNumeros = mapToIds(
      TipoServicoChamado,
      DescEnum_TipoServicoChamado,
      tipoChamado
    );
    const areaNumeros = mapToIds(AreaChamado, DescEnum_AreaChamado, area);

    const params = {
      cliente_id: user.cliente_id,
      dataIni: abertoDe ? abertoDe : "01/07/2010",
      dataFim: abertoAte ? abertoAte : "31/12/2100",
      situacoes: JSON.stringify(situacaoNumeros),
      areas: JSON.stringify(areaNumeros),
      tipos: JSON.stringify(tipoServicoNumeros),
    };

    const response = await api
      .get("/chamado/cons", { params })
      .catch((error) => {
        handleError(error, "Erro ao Consultar");
        setLoading(false);
      });

    if (response && response.data) {
      setChamados(response.data);
    } else {
      // toast.error("Erro ao carregar tickets");
    }
    setLoading(false);
  }

  return (
    <div>
      <Menu />
      <div className="content">
        <Cabecalho descricao={`Consulta de Solicitações`} titulo="Solicitações">
          <FaBook size={24} />
        </Cabecalho>

        <div className="content_solicitacao">
          <Filtros
            filters={filters}
            setFilters={setFilters}
            tiposChamado={tiposChamado}
            situacoesChamado={situacoesChamado}
            areas={areas}
          />
          <GridChamados chamados={chamados} />
        </div>
      </div>
    </div>
  );
}
