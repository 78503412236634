import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
import { DropContainer, FileInputContainer, UploadMessage } from "./styles";

const FileUploader = ({ onFilesChange }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const totalFiles = files.length + acceptedFiles.length;

      if (totalFiles > 30) {
        Swal.fire({
          title: "Alerta!",
          text: "Máximo de anexos não pode ultrapassar 30.",
          icon: "warning",
        });
        return; // Impede a adição de novos arquivos se ultrapassar o limite
      }

      // Atualiza o estado com os novos arquivos
      const updatedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([updatedFiles]);

      // Chama a função de retorno com a lista atualizada
      onFilesChange([...files, ...updatedFiles]);
      setFiles([]);
    },
    [files, onFilesChange]
  );

  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return <UploadMessage>Arraste arquivos aqui...</UploadMessage>;
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: "",
      // accept: {
      //   "image/*": [], // Imagens
      //   "application/pdf": [], // PDFs
      //   "text/plain": [], // Arquivos de texto
      //   "application/msword": [], // Documentos do Word
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // Planilha do Excel
      //   "audio/*": [], // Áudio
      //   "video/*": [], // Vídeo
      //   "text/csv": [], // CSV
      //   "application/zip": [], // Arquivos ZIP
      //   "application/x-7z-compressed": [], // Arquivos 7z
      //   "application/x-rar-compressed": [], // Arquivos RAR
      // },
    });

  return (
    <FileInputContainer>
      <DropContainer {...getRootProps()}>
        <input {...getInputProps()} />
        {renderDragMessage(isDragActive, isDragReject)}
      </DropContainer>
    </FileInputContainer>
  );
};

export default FileUploader;
