import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";

export default function CmxInput({ obrigatorio, ...props }) {
  const [erro, setErro] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus(); // Foca no input quando o componente é montado
    }
  }, [props.autoFocus]);

  const handleBlur = () => {
    if (obrigatorio && !inputRef.current.value) {
      setErro(true);
    } else {
      setErro(false);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        className={`${styles.input} ${props.disabled ? "desabilitado" : ""}`}
        onBlur={handleBlur}
        {...props}
      />
      {!props.disabled && erro && (
        <span className={styles.errorMessage}>Campo obrigatório!</span>
      )}
    </>
  );
}

export function CmxTextArea({ obrigatorio, ...props }) {
  const [erro, setErro] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus(); // Foca no input quando o componente é montado
    }
  }, [props.autoFocus]);

  const handleBlur = () => {
    if (obrigatorio && !inputRef.current.value) {
      setErro(true);
    } else {
      setErro(false);
    }
  };

  return (
    <>
      <textarea
        ref={inputRef}
        className={`${styles.input} ${props.disabled ? "desabilitado" : ""}`}
        onBlur={handleBlur}
        {...props}
      ></textarea>
      {!props.disabled && erro && (
        <span className={styles.errorMessage}>Campo obrigatório!</span>
      )}
    </>
  );
}

export function CmxComboBox({ obrigatorio, ...props }) {
  const [erro, setErro] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current.focus(); // Foca no input quando o componente é montado
    }
  }, [props.autoFocus]);

  const handleBlur = () => {
    if (obrigatorio && !inputRef.current.value) {
      setErro(true);
    } else {
      setErro(false);
    }
  };

  return (
    <>
      <select
        ref={inputRef}
        className={`${styles.input} ${props.disabled ? "desabilitado" : ""}`}
        onBlur={handleBlur}
        {...props}
      ></select>
      {!props.disabled && erro && (
        <span className={styles.errorMessage}>Campo obrigatório!</span>
      )}
    </>
  );
}
