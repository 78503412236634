import { FaSpinner } from "react-icons/fa";
import styles from "./styles.module.scss";

export default function CmxButton({ loading, children, ...rest }) {
  return (
    <button className={styles.button} disabled={loading} {...rest}>
      {loading ? <FaSpinner color="#fff" size={16} /> : <a>{children}</a>}
    </button>
  );
}

/*
export function CmxButtonLogin({ loading, children, ...rest }) {
  return (
    <button className={styles.button} disabled={loading} {...rest}>
      {loading ? <FaSpinner color='#fff' size={16} /> : <a>{children}</a>}
    </button>
  );
}
*/
