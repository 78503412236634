import React, { useEffect, useState } from "react";
import { TipoNoticia } from "../../enums/enums";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

const Noticia = ({ titulo, noticia, data, tipo }) => {
  const [imagemUrl, setImagemUrl] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      if (tipo == TipoNoticia.Imagem) {
        try {
          const response = await api
            .get("/noticia/downloadimage", {
              params: { filename: noticia },
              responseType: "blob",
            })
            .catch((error) => {
              handleError(error, "Erro ao Baixar Imagem");
            });

          if (response && response.data) {
            const url = URL.createObjectURL(response.data);
            setImagemUrl(url);
          } else {
            console.error("Erro ao buscar a imagem:", response?.statusText);
          }
        } catch (error) {
          console.error("Erro ao buscar a imagem:", error);
        }
      }
    };

    fetchImage();
  }, [noticia, tipo]);

  return (
    <div className="noticia">
      <h3 className="titulo">{titulo}</h3>

      {tipo == TipoNoticia.Texto && <p className="noticia-texto">{noticia}</p>}
      {tipo == TipoNoticia.Imagem && imagemUrl && (
        <img className="noticia-imagem" src={imagemUrl} alt={titulo} />
      )}
      <span className="data">{data}</span>
    </div>
  );
};

export default Noticia;
