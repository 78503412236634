import { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { FiHome } from "react-icons/fi";
import { TiWarningOutline } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cabecalho from "../../components/Cabecalho";
import Menu from "../../components/Menu";
import Noticia from "../../components/Noticia";
import { AuthContext } from "../../context/auth";
import {
  AreaChamado,
  DescEnum_AreaChamado,
  DescEnum_ChamadoSit,
  DescEnum_TipoServicoChamado,
  SituacaoChamado,
  TipoServicoChamado,
} from "../../enums/enums";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

export default function Home() {
  const { user } = useContext(AuthContext);
  const [qtdAberto, setQtdAberto] = useState(0);
  const [qtdAnalise, setQtdAnalise] = useState(0);
  const [qtdElabOrcam, setQtdElabOrcam] = useState(0);
  const [qtdAgAprov, setQtdAgAprov] = useState(0);
  const [qtdProgresso, setQtdProgresso] = useState(0);
  const [qtdBaixado, setQtdBaixado] = useState(0);
  const [possuiAguardandoAprov, setPossuiAguardandoAprov] = useState(0);
  // const [qtdCancelado, setQtdCancelado] = useState(0);

  const [noticias, setNoticias] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    carregarGrafico();
    carregarNoticias();
  }, []);

  async function carregarGrafico() {
    const response = await api
      .get("/chamado/graf", {
        params: {
          cliente_id: user.cliente_id,
        },
      })
      .catch((error) => {
        toast("erro: " + error);
      });

    setQtdAberto(response.data.qtdAberto);
    setQtdAnalise(response.data.qtdAnalise);
    setQtdElabOrcam(response.data.qtdElabOrcam);
    setQtdAgAprov(response.data.qtdAgAprov);
    setQtdProgresso(response.data.qtdProgresso);
    setQtdBaixado(response.data.qtdBaixado);
    setPossuiAguardandoAprov(response.data.possuiAguardandoAprov);
    // setQtdCancelado(response.data.qtdCancelado);
  }

  async function carregarNoticias() {
    const response = await api.get("/noticia/cons").catch((error) => {
      handleError(error, "Erro ao Consultar Noticia");
    });

    if (response && response.data) {
      setNoticias(response.data);
    } else {
      // toast.error("Erro ao carregar tickets");
    }
  }

  const dadosGrafico = [
    ["Task", "Qtd"],
    ["Em Aberto", qtdAberto],
    ["Em Analise", qtdAnalise],
    ["Elaborando Orçamento", qtdElabOrcam],
    ["Aguardando Aprovação", qtdAgAprov],
    ["Em Progresso", qtdProgresso],
    ["Baixado", qtdBaixado],
    // ["Cancelado", qtdCancelado],
  ];

  const options = {
    // pieHole: 0.1, // Adiciona um buraco no meio do gráfico de rosca
    legend: {
      position: "right", // Posiciona a legenda à direita
      alignment: "center", // Ajusta o alinhamento da legenda
      textStyle: {
        color: "black", // Cor do texto da legenda
        fontSize: 12, // Tamanho da fonte da legenda
      },
    },
  };

  function handleOpenChamado() {
    try {
      const filters = {
        abertoDe: "01/07/2010",
        abertoAte: "31/12/2100",
        situacaoChamado: [
          DescEnum_ChamadoSit[SituacaoChamado.Aguardando_Aprovacao],
        ],
        tipoChamado: [
          DescEnum_TipoServicoChamado[TipoServicoChamado.Duvida],
          DescEnum_TipoServicoChamado[TipoServicoChamado.Erro],
          DescEnum_TipoServicoChamado[TipoServicoChamado.Servico],
        ],
        area: [
          DescEnum_AreaChamado[AreaChamado.Comercial],
          DescEnum_AreaChamado[AreaChamado.Financeiro],
          DescEnum_AreaChamado[AreaChamado.Industrial],
          DescEnum_AreaChamado[AreaChamado.Controladoria],
        ],
      };

      // Salva no localStorage
      localStorage.setItem("cmx_filters", JSON.stringify(filters));

      navigate("/app/chamados");
    } catch (error) {
      handleError(error, "Erro ao abrir tela de Consulta de Chamado");
    }
  }

  return (
    <div>
      <Menu />
      <div className="content">
        <Cabecalho descricao={`Olá, ${user.nome}!`} titulo="Portal de Chamados">
          <FiHome size={24} />
        </Cabecalho>

        <div className="content_home">
          <div className="left_side">
            {qtdAgAprov > 0 && possuiAguardandoAprov && (
              <div className="warning_area" onClick={handleOpenChamado}>
                <TiWarningOutline size={66} />
                <span>
                  Há Chamados parados aguardando aprovação de Orçamento
                </span>
              </div>
            )}
            <h2 className="titulo_home">CHAMADOS RECENTES</h2>
            <div className="grafico_area">
              <Chart
                chartType="PieChart"
                data={dadosGrafico}
                options={options}
                width={"100%"}
                height={"100%"}
              />
            </div>
          </div>
          <div className="right_side">
            <h2 className="titulo_home">INFORMAÇÕES</h2>
            {noticias.map((noticia, index) => (
              <Noticia key={index} {...noticia} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
