import { destroyCookie, parseCookies, setCookie } from "nookies";
import { createContext, useEffect, useState } from "react";
import { api } from "../services/apiClient";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleError } from "../services/erros/ErrorHandler";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function loadUser() {
      const { "@portal.customax.token": token } = parseCookies();

      if (token) {
        api
          .get("/usuario/info")
          .then((response) => {
            const {
              id,
              cliente_id,
              nome,
              email,
              cliente,
              login,
              chamado_cliente_obrigatorio,
            } = response.data;

            let data = {
              id,
              cliente_id,
              nome,
              email,
              cliente,
              login,
              chamado_cliente_obrigatorio,
            };
            setUser(data);
          })
          .catch((error) => {
            handleError(error, "Erro ao fazer login direto");
            logout();
          });

        setLoading(false);
      }

      setLoading(false);
    }

    loadUser();
  }, []);

  async function signIn(user, pass) {
    setLoadingAuth(true);
    try {
      const response = await api.post("/usuario/login", {
        login: user,
        senha: pass,
      });

      const {
        id,
        cliente_id,
        nome,
        email,
        cliente,
        login,
        chamado_cliente_obrigatorio,
        token,
      } = response.data;

      setCookie(undefined, "@portal.customax.token", token, {
        maxAge: 60 * 60 * 24 * 30, // expira em 30 dias
        path: "/", // todos caminhos tem acesso ao cookie
      });

      let data = {
        id,
        cliente_id,
        nome,
        email,
        cliente,
        login,
        chamado_cliente_obrigatorio,
        cliente,
      };
      setUser(data);

      api.defaults.headers["Authorization"] = `Bearer ${token}`;

      setLoadingAuth(false);

      toast.success("Sejá bem vindo ao Sistema!", {
        theme: "dark",
      });

      // navigate('/');
    } catch (error) {
      handleError(error, "Erro na requisição");
    }
  }

  async function logout() {
    destroyCookie(undefined, "@portal.customax.token");
    console.clear();
    //toast.error('fez logout do sistema');
    setUser(null);
    navigate("/");
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        signIn,
        logout,
        loadingAuth,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
