import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";
import "../Login/style.css";

export default function Login() {
  const [login, setLogin] = useState("");

  useEffect(() => {
    document.title = "Solicitar Nova Senha";
  }, []);

  const navigate = useNavigate();

  async function handleToLogin(e) {
    e.preventDefault();
    navigate("/");
  }

  async function handleSendMail(e) {
    e.preventDefault();
    let mail = "";

    if (!login) {
      toast.warning("Informar um Login válido");
      return;
    }

    try {
      const response = await api.get("/usuario/getmail", {
        params: {
          login: login,
        },
      });

      mail = response.data.email;

      navigate("/");

      Swal.fire({
        title: "E-mail enviado!",
        text: `E-mail de redefinição de senha enviado para '${mail}'`,
        icon: "info",
      });
    } catch (error) {
      handleError(error, "Erro ao retornar e-mail");
    }
  }

  return (
    <div className="container_login">
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="color_login"></div>
      <div className="box">
        <div className="square" style={{ "--var1": 0 }}></div>
        <div className="square" style={{ "--var1": 1 }}></div>
        <div className="square" style={{ "--var1": 2 }}></div>
        <div className="square" style={{ "--var1": 3 }}></div>
        <div className="square" style={{ "--var1": 4 }}></div>
        <div className="login_area">
          <div className="form">
            <h2>Solicitar Redefinição de Senha</h2>
            <form>
              <div className="input_box">
                <input
                  type="text"
                  placeholder="Informe seu Login"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
              </div>

              <div className="input_box">
                <input
                  type="submit"
                  value={"Redefinir Senha"}
                  onClick={handleSendMail}
                />
                <br />
                <input
                  type="submit"
                  value={"Retornar"}
                  onClick={handleToLogin}
                  style={{ marginTop: -6 }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
