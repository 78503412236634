import styled, { css } from 'styled-components';

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const FileInputContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 6px;
`;

export const DropContainer = styled.div.attrs({ className: 'dropzone' })`
  border: 1px dashed #dbd9d9fa;
  border-radius: 6px;
  cursor: pointer;
  height: 41px;
  min-width: 100%;

  transition: height 0.2s ease;

  /* ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject}; */
`;

const messageColors = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5',
};
export const UploadMessage = styled.p`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 9px 0;
`;
