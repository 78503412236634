import Swal from "sweetalert2";

export function handleError(error, context = "Erro na requisição") {
  let msg = context;

  if (error.response?.data?.error) {
    msg = error.response.data.error;
  } else if (error.message) {
    msg = `${context}: ${error.message}`;
  }

  // Exibir erro com SweetAlert2
  Swal.fire({
    title: "Erro",
    text: msg,
    icon: "error",
  });

  // Se no futuro você quiser mudar para um toast:
  // toast.error(msg); // Usando uma biblioteca de toasts, por exemplo.
}
