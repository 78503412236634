import React, { useEffect, useRef } from "react";
import { DescEnum_ChamadoSit, SituacaoChamado } from "../../enums/enums";
import "./style.css";

import { MdAttachFile, MdSend } from "react-icons/md";
import Swal from "sweetalert2";

export function MessageChat({ onSendAnexo, onSendMsg, chamado }) {
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const textarea = document.getElementById("acaoInput");
    textarea.addEventListener("input", resizeTextarea);

    return () => {
      textarea.removeEventListener("input", resizeTextarea);
    };
  }, []);

  function resizeTextarea() {
    const textarea = document.getElementById("acaoInput");
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  function handleSandAnexo() {
    if (
      chamado?.situacao === SituacaoChamado.Cancelado ||
      chamado?.situacao === SituacaoChamado.Baixado ||
      chamado?.situacao === SituacaoChamado.Encerrado
    ) {
      Swal.fire({
        title: "Alerta!",
        text: `Este Chamado se encontra na situação ${
          DescEnum_ChamadoSit[chamado?.situacao]
        }, 
      e portanto você não pode mais criar ação neste Chamado.`,
        icon: "warning",
      });

      return;
    }

    fileInputRef.current.value = "";
    fileInputRef.current.click();
  }

  function handleFileChange(event) {
    const files = event.target.files;

    if (onSendAnexo && files.length > 0) {
      let htmlText = "";
      for (let index = 0; index < files.length; index++) {
        let name = files[index].name;
        htmlText += `<p>${name}</p>`;
      }

      Swal.fire({
        title: "Confirmar o Envio dos Arquivos Abaixo?",
        html: htmlText,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      }).then((result) => {
        if (result.isConfirmed) {
          onSendAnexo(files);
        }
      });
    }
  }

  function handleSandMsg() {
    if (onSendMsg) {
      onSendMsg(textareaRef.current.value);
      // Limpar o textarea após enviar a mensagem
      textareaRef.current.value = "";
      resizeTextarea();
    }
  }

  return (
    <div className="right_footer">
      <span>
        <MdAttachFile
          title="Enviar Arquivo"
          size={24}
          onClick={() => handleSandAnexo()}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple // Permitir a seleção de vários arquivos
        />
      </span>
      <textarea
        id="acaoInput"
        ref={textareaRef}
        rows="1" // Defina inicialmente como uma única linha
        placeholder="Mensagem"
      ></textarea>
      <span>
        <MdSend
          title="Enviar Mensagem"
          size={24}
          onClick={() => handleSandMsg()}
        />
      </span>
    </div>
  );
}

export default MessageChat;
