import React, { useEffect, useState } from "react";
import { BiCheck, BiSolidChevronDown } from "react-icons/bi";
import "./style.css";

const MultiSelect = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  uniqueId,
}) => {
  const [listaAtiva, setListaAtiva] = useState(false);

  useEffect(() => {
    const fecharListaForaClick = (event) => {
      if (
        listaAtiva &&
        !document
          .getElementById(`dropDown-multiselect-${uniqueId}`)
          .contains(event.target)
      ) {
        setListaAtiva(false);
      }
    };

    const fecharListaTeclaEscape = (event) => {
      if (listaAtiva && event.key === "Escape") {
        setListaAtiva(false);
      }
    };

    document.addEventListener("mousedown", fecharListaForaClick);
    document.addEventListener("keydown", fecharListaTeclaEscape);

    return () => {
      document.removeEventListener("mousedown", fecharListaForaClick);
      document.removeEventListener("keydown", fecharListaTeclaEscape);
    };
  }, [listaAtiva, uniqueId]);

  const toggleLista = () => {
    setListaAtiva(!listaAtiva);
  };

  const toggleItem = (option) => {
    const index = selectedOptions.indexOf(option);

    if (index === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions.splice(index, 1);
      setSelectedOptions(newSelectedOptions);
    }
  };

  const selectedOptionsText = () => {
    const numOptions = selectedOptions.length;

    if (numOptions === 0) {
      return `Selecione ${label}`;
    } else {
      return selectedOptions.join(", ");
    }
  };

  return (
    <div
      id={`dropDown-multiselect-${uniqueId}`}
      className="dropDown-multiselect"
    >
      <div
        className={`btn-select ${listaAtiva ? "active-multiselect" : ""}`}
        onClick={toggleLista}
      >
        <span className="btn-text">{selectedOptionsText()}</span>
        <span className="arrow-down">
          <BiSolidChevronDown size={24} />
        </span>
      </div>
      <ul
        className={`option-multiselect ${
          listaAtiva ? "active-multiselect" : ""
        }`}
      >
        {options.map((option, index) => (
          <li
            key={index}
            id={`item_${option ? option.replace(/\s/g, "_") : index}`}
            className={`checkbox-item ${
              selectedOptions.includes(option) ? "item-checked" : ""
            }`}
            onClick={() => toggleItem(option)}
          >
            <span className="checkbox-multiselect">
              <BiCheck size={24} className="check-icon" />
            </span>
            <span className="checkbox-item-text">{option}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiSelect;
