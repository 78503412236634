import { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "./context/auth";
import RoutesApp from "./routes";

function App() {
  useEffect(() => {
    const favicon = document.getElementById("favicon");

    if (favicon) {
      // favicon.href = './public/favicon.png';
      favicon.href = process.env.PUBLIC_URL + "/favicon.ico";
    }
  }, []);
  return (
    <BrowserRouter locale="pt-BR">
      <AuthProvider>
        <ToastContainer autoClose={3000} />
        <RoutesApp />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
