import axios /*, { AxiosError }*/ from "axios";
import { destroyCookie, parseCookies } from "nookies";
import { AuthTokenError } from "./erros/AuthTokenError";

export function setupAPIClient(ctx = undefined) {
  const cookies = parseCookies(ctx);
  const api = axios.create({
    //baseURL: "http://localhost:3033", //process.env.REACT_APP_BASE_URL,
    baseURL: "https://atendimento.customax.inf.br:3033",
    headers: {
      Authorization: `Bearer ${cookies["@portal.customax.token"]}`,
    },
  });
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        // quando ocorre erro 401 devemos deslogar o User
        if (typeof window !== undefined) {
          //Chamar Função para deslogar
          destroyCookie(undefined, "@portal.customax.token");
          console.clear();
          //toast.error('fez logout do sistema');
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }
      return Promise.reject(error);
    }
  );

  return api;
}
