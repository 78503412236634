import styled from 'styled-components';

export const Container = styled.ul`
  width: 100%;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding: 0 12px;
    border-radius: 6px;
    background-color: #f1f2f5;
    color: #555;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 12px;

  svg {
    margin-top: 6px;
    width: 27px;
    height: 27px;
    color: #999;
  }
`;
