import Tooltip from "@mui/material/Tooltip";
import { filesize } from "filesize";
import { uniqueId } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Cabecalho from "../../components/Cabecalho";
import Menu from "../../components/Menu";
import CmxButton from "../../components/ui/Button";
import CmxCheckBox from "../../components/ui/CheckBox";
import FileUploader from "../../components/ui/FileUploader";
import FileUploaderList from "../../components/ui/FileUploaderList";
import CmxInput, { CmxComboBox, CmxTextArea } from "../../components/ui/Input";
import { AuthContext } from "../../context/auth";
import { AcaoDe, TipoAcaoChamado } from "../../enums/enums";
import { api } from "../../services/apiClient";
import { handleError } from "../../services/erros/ErrorHandler";

import "./style.css";

export default function NovaSolicitacao() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [assunto, setAssunto] = useState("");
  const [chamadoCliente, setChamadoCliente] = useState("");
  const [emailSolicitante, setEmailSolicitante] = useState(user.email);
  const [contatoTISelected, setContatoTISelected] = useState(user.id);
  const [tipoSelected, setTipoSelected] = useState("");
  const [urgenciaSelected, setUrgenciaSelected] = useState("");
  const [descricao, setDescricao] = useState("");
  const [testeRealizado, setTesteRealizado] = useState("");
  const [rotina, setRotina] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [salvando, setSalvando] = useState(false);
  const [contatosTI, setContatosTI] = useState([]);
  const [checkedAreas, setCheckedKAreas] = useState([]);
  const [politicaIsCheked, setPoliticaIsCheked] = useState(false);

  const [impacto, setImpacto] = useState(0);
  const [chamadoID, setChamadoID] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [notifCriacaoLizard, setNotifCriacaoLizard] = useState(null);
  const [notifEdicaoLizard, setNotifEdicaoLizard] = useState(null);
  const [reabrirLizard, setReabrirLizard] = useState(null);

  const checkboxes = [
    { area: "1", descricao: "Comercial" },
    { area: "2", descricao: "Financeiro" },
    { area: "3", descricao: "Industrial" },
    { area: "4", descricao: "Controladoria" },
  ];

  useEffect(() => {
    const fetchContatosTI = async () => {
      try {
        const response = await api.get("/usuario/contatosti", {
          params: {
            cliente_id: user.cliente_id,
          },
        });

        setContatosTI(response.data);

        if (id) {
          const response = await api.get("/chamado/get", {
            params: {
              ticketId: id,
            },
          });

          if (
            response.data &&
            user.cliente_id === response.data.contato_ti.cliente_id
          ) {
            setAssunto(response.data.assunto);
            setChamadoCliente(response.data.chamado_cliente);
            setEmailSolicitante(response.data.solicitante_email);
            setContatoTISelected(response.data.contato_ti.id);
            setTipoSelected(response.data.tipo);
            setUrgenciaSelected(response.data.urgencia);
            setDescricao(response.data.descricao);
            setRotina(response.data.rotina);

            const areasArray = response.data.area_atuacao.split("^");
            const numericArray = areasArray.map((area) => area.toString());

            setCheckedKAreas(numericArray);

            setImpacto(response.data.impacto);
            setChamadoID(response.data.chamado_id);
            setSituacao(response.data.situacao);
            setNotifCriacaoLizard(response.data.notif_criacao_lizard);
            setNotifEdicaoLizard(response.data.notif_edicao_lizard);
            setReabrirLizard(response.data.reabrir_lizard);
          } else {
            toast.warning(`Chamado ${id} não encontrado!`);
            navigate("/app/chamado");
          }
          setPoliticaIsCheked(true);
        }
      } catch (error) {
        handleError(error, "Erro ao retornar dados");
      }
    };

    fetchContatosTI();
  }, [id]);

  const handleCheckboxChange = (area, isChecked) => {
    if (isChecked) {
      setCheckedKAreas((prevAreas) => [...prevAreas, area]);
    } else {
      setCheckedKAreas((prevAreas) =>
        prevAreas.filter((prevArea) => prevArea !== area)
      );
    }
    setCheckedKAreas((prevKeys) =>
      [...prevKeys].sort((a, b) => a.localeCompare(b))
    );
  };

  const handleUpload = (newFiles) => {
    const totalFiles = uploadedFiles.length + newFiles.length;

    if (totalFiles > 30) {
      Swal.fire({
        title: "Alerta!",
        text: "Máximo de anexos não pode ultrapassar 30.",
        icon: "warning",
      });
      return; // Impede a adição de novos arquivos se ultrapassar o limite
    }

    const files = newFiles.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      ReadableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const handleDelete = (fileId) => {
    // Lógica para excluir o arquivo com o ID correspondente
    const updatedFiles = uploadedFiles.filter((file) => file.id !== fileId);
    setUploadedFiles(updatedFiles);
  };

  async function handleSave(e) {
    e.preventDefault();

    if (!politicaIsCheked) {
      Swal.fire({
        title: "Alerta!",
        text: "Por favor, assinale a caixa referente à Política de Privacidade para continuar.",
        icon: "warning",
      });

      return;
    }

    setSalvando(true);

    try {
      var descricaoNova = descricao;

      if (
        testeRealizado !== null &&
        testeRealizado !== undefined &&
        testeRealizado.trim() !== ""
      ) {
        // Adicionar uma linha de separação
        descricaoNova += "\n-------------------------------------------\n";

        descricaoNova += "Testes Realizados\n";

        descricaoNova += testeRealizado;
      }

      if (!id) {
        const data = {
          cliente_id: user.cliente_id,
          assunto: assunto,
          solicitante_email: emailSolicitante,
          tipo: +tipoSelected,
          urgencia: +urgenciaSelected,
          rotina: rotina,
          descricao: descricaoNova,
          contato_ti_id: +contatoTISelected,
          chamado_cliente: chamadoCliente,
          area_atuacao: checkedAreas.join("^"),
          possui_anexo: uploadedFiles.length > 0,
        };

        if (
          data.assunto &&
          (!user.chamado_cliente_obrigatorio || data.chamado_cliente) &&
          data.solicitante_email &&
          data.contato_ti_id &&
          data.area_atuacao &&
          data.tipo &&
          data.urgencia &&
          data.descricao
        ) {
          const result = await api.post("/chamado/cad", data);

          const uploadPromises = uploadedFiles.map((file) =>
            processUpload(file, result.data.id, user.nome)
          );

          // Aguardando a conclusão de todas as Promessas antes de prosseguir
          await Promise.all(uploadPromises);

          setAssunto("");
          setChamadoCliente("");
          setEmailSolicitante("");
          setContatoTISelected("");
          setTipoSelected("");
          setUrgenciaSelected("");
          setDescricao("");
          setTesteRealizado("");
          setRotina("");
          setCheckedKAreas([]);
          setUploadedFiles([]);

          Swal.fire({
            title: "Sucesso!",
            text: `O Chamado '${result.data.id}' foi cadastrado com sucesso!`,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Visualizar Chamado",
            cancelButtonText: "OK",
          }).then((response) => {
            if (response.isConfirmed) {
              navigate(`/app/chamados/${result.data.id}`);
            }
          });
        } else {
          toast.warning(`Por favor, preencha todos os campos obrigatórios.`);
        }
      } else {
        const data = {
          ticketId: +id,
          assunto: assunto,
          solicitante_email: emailSolicitante,
          tipo: +tipoSelected,
          impacto: +impacto,
          urgencia: +urgenciaSelected,
          rotina: rotina,
          descricao: descricaoNova,
          contato_ti_id: +contatoTISelected,
          usuario_id: user.id,
          chamado_cliente: chamadoCliente,
          chamado_id: +chamadoID,
          area_atuacao: checkedAreas.join("^"),
          situacao: situacao,
          notifCriacaoLizard: notifCriacaoLizard,
          notifEdicaoLizard: notifEdicaoLizard,
          reabrirLizard: reabrirLizard,
        };

        if (
          data.assunto &&
          (!user.chamado_cliente_obrigatorio || data.chamado_cliente) &&
          data.solicitante_email &&
          data.contato_ti_id &&
          data.area_atuacao &&
          data.tipo &&
          data.urgencia &&
          data.descricao
        ) {
          const result = await api.put("/chamado/edit", data);

          const uploadPromises = uploadedFiles.map((file) =>
            processUpload(file, result.data.id, user.nome)
          );

          // Aguardando a conclusão de todas as Promessas antes de prosseguir
          await Promise.all(uploadPromises);

          setAssunto("");
          setChamadoCliente("");
          setEmailSolicitante("");
          setContatoTISelected("");
          setTipoSelected("");
          setUrgenciaSelected("");
          setDescricao("");
          setTesteRealizado("");
          setRotina("");
          setCheckedKAreas([]);
          setUploadedFiles([]);

          toast.success("Alterado com sucesso!");
        } else {
          toast.warning(`Por favor, preencha todos os campos obrigatórios.`);
        }
      }
    } catch (error) {
      handleError(error, "Erro ao salvar Chamado");
    }

    setSalvando(false);
  }

  const updateFile = (id, data) => {
    setUploadedFiles((prevFiles) => {
      return prevFiles.map((file) =>
        file.id === id ? { ...file, ...data } : file
      );
    });
  };

  const processUpload = async (uploadFile, chamadoID, criadoPor) => {
    const data = new FormData();

    data.append("file", uploadFile.file);
    data.append("descricao", uploadFile.name);
    data.append("tipo", TipoAcaoChamado.Anexo);
    data.append("acao_de", AcaoDe.Cliente);
    data.append("criado_por", criadoPor);
    data.append("chamado_id", chamadoID);
    data.append("cliente", user.cliente);

    const result = await api
      .post("chamadoacao/cad", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          updateFile(uploadFile.id, {
            progress,
          });
        },
      })
      .then((response) => {
        updateFile(uploadFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.url,
        });
      })
      .catch((error) => {
        updateFile(uploadFile.id, {
          error: true,
        });
      });
  };

  return (
    <div>
      <Menu />
      <div className="content">
        <Cabecalho descricao="Novo Chamado">
          <FiPlusCircle size={24} />
        </Cabecalho>

        <div className="content_area">
          <form action="form-profile">
            <div className="row">
              <div className="col col-9">
                <label>Assunto *</label>
                <CmxInput
                  type="text"
                  maxLength={60}
                  obrigatorio={true}
                  autoFocus={true}
                  value={assunto}
                  onChange={(e) => setAssunto(e.target.value)}
                />
              </div>
              <div className="col col-3">
                <div className="campo-com-icone">
                  <label>
                    {user.chamado_cliente_obrigatorio
                      ? "Chamado Cliente *"
                      : "Chamado Cliente"}
                  </label>
                  <Tooltip
                    title={
                      <>Informe o número do chamado em seu sistema, se tiver.</>
                    }
                  >
                    <span>
                      <IoMdInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                </div>
                <CmxInput
                  type="text"
                  maxLength={30}
                  obrigatorio={user.chamado_cliente_obrigatorio}
                  value={chamadoCliente}
                  onChange={(e) => setChamadoCliente(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                <div className="campo-com-icone">
                  <label>E-mail Solicitante *</label>
                  <Tooltip
                    title={
                      <>
                        Informe o e-mail do seu contato que solicitou o serviço.
                      </>
                    }
                  >
                    <span>
                      <IoMdInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                </div>
                <CmxInput
                  type="text"
                  maxLength={250}
                  obrigatorio={true}
                  value={emailSolicitante}
                  onChange={(e) => setEmailSolicitante(e.target.value)}
                />
              </div>
              <div className="col col-6">
                <label>E-mail TI *</label>
                <CmxComboBox
                  obrigatorio={true}
                  value={contatoTISelected}
                  onChange={(e) => setContatoTISelected(e.target.value)}
                >
                  <option value="">--Selecione um contato TI</option>
                  {contatosTI.map((contato) => (
                    <option key={contato.id} value={contato.id}>
                      {contato.email}
                    </option>
                  ))}
                </CmxComboBox>
              </div>
            </div>

            <div className="area">
              <label>Área *</label>

              <div className="checkbox-container">
                {checkboxes.map((item) => (
                  <CmxCheckBox
                    key={item.area}
                    area={item.area}
                    descricao={item.descricao}
                    isChecked={checkedAreas.includes(item.area)}
                    onCheckboxChange={(isChecked) =>
                      handleCheckboxChange(item.area, isChecked)
                    }
                  />
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col col-4">
                <div className="campo-com-icone">
                  <label>Tipo *</label>
                  <Tooltip
                    title={
                      <>
                        Serviço: uma solicitação nova.
                        <br />
                        Erro: algo não está como deveria.
                        <br />
                        Dúvida: necessita de suporte.
                      </>
                    }
                  >
                    <span>
                      <IoMdInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                </div>
                <CmxComboBox
                  obrigatorio={true}
                  value={tipoSelected}
                  onChange={(e) => setTipoSelected(e.target.value)}
                >
                  <option value="">--Selecione um Tipo</option>
                  <option value="1">Dúvida</option>
                  <option value="2">Erro</option>
                  <option value="3">Serviço</option>
                </CmxComboBox>
              </div>

              <div className="col col-4">
                <div className="campo-com-icone">
                  <label>Urgência *</label>
                  <Tooltip
                    title={
                      <>
                        Alta: setores parados, impacto financeiro alto.
                        <br />
                        Média: solicitações normais do dia-a-dia.
                        <br />
                        Baixa: solicitações normais que não tem pressa de serem
                        feitas.
                      </>
                    }
                  >
                    <span>
                      <IoMdInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                </div>
                <CmxComboBox
                  obrigatorio={true}
                  value={urgenciaSelected}
                  onChange={(e) => setUrgenciaSelected(e.target.value)}
                >
                  <option value="">--Selecione a Urgência</option>
                  <option value="3">Baixa</option>
                  <option value="2">Média</option>
                  <option value="1">Alta</option>
                </CmxComboBox>
              </div>

              <div className="col col-4">
                <div className="campo-com-icone">
                  <label>Rotina(s)</label>
                  <Tooltip
                    title={
                      <>
                        Informe o nome da(s) rotina(s) ou tela(s) que serve de
                        ref. nesse chamado.
                        <br />
                        OBS: Separar com vírgula(,) caso tenha mais de uma(1).
                      </>
                    }
                  >
                    <span>
                      <IoMdInformationCircleOutline size={15} />
                    </span>
                  </Tooltip>
                </div>
                <CmxInput
                  type="text"
                  maxLength={250}
                  value={rotina}
                  onChange={(e) => setRotina(e.target.value)}
                />
              </div>
            </div>

            <div className="col">
              <label>Descrição *</label>
              <CmxTextArea
                type="text"
                obrigatorio={true}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                style={{ height: 150 }}
              />
            </div>

            <div className="col">
              <div className="campo-com-icone">
                <label>Testes Realizados</label>
                <Tooltip
                  title={
                    <>
                      Para o caso de chamados de erro, informe os
                      testes/verificações que você fez.
                      <br />
                      Isso ajuda no diagnóstico do problema.
                    </>
                  }
                >
                  <span>
                    <IoMdInformationCircleOutline size={15} />
                  </span>
                </Tooltip>
              </div>
              <CmxTextArea
                type="text"
                value={testeRealizado}
                onChange={(e) => setTesteRealizado(e.target.value)}
              />
            </div>

            <div className="col">
              <label>Anexo(s)</label>
              <FileUploader onFilesChange={handleUpload} />
              {!!uploadedFiles.length && (
                <FileUploaderList
                  files={uploadedFiles}
                  onDelete={handleDelete}
                />
              )}
            </div>

            <div className="col politica_privacidade">
              <CmxCheckBox
                fontSize={11}
                width={"100%"}
                height={"100%"}
                descricao={`Ao informar dados à Customax, sua empresa será responsável pelo compartilhamento como Controladora de Dados. 
                Siga a Lei Geral de Proteção de Dados (Lei 13.709/2018) e as diretrizes legais. Compartilhe apenas dados necessários. 
                Não compartilhe dados sensíveis. Consulte nossa 'Política de Privacidade'. Para dúvidas, contate nosso canal LGPD.`}
                isChecked={politicaIsCheked}
                onCheckboxChange={(isChecked) => setPoliticaIsCheked(isChecked)}
              />
            </div>

            <CmxButton
              loading={salvando}
              enabled={politicaIsCheked}
              style={{ marginLeft: "12px", marginTop: "12px" }}
              type="submit"
              onClick={handleSave}
            >
              Salvar
            </CmxButton>
          </form>
        </div>
      </div>
    </div>
  );
}
