import { useContext, useEffect, useRef, useState } from "react";
import { MdLogout, MdPassword } from "react-icons/md";
import { Link } from "react-router-dom";
import avatar from "../../assets/avatar.png";
import { AuthContext } from "../../context/auth";
import "./style.css";

export default function Cabecalho({ children, descricao, titulo = "" }) {
  useEffect(() => {
    document.title = titulo !== "" ? titulo : descricao;
  }, []);

  const { logout, user } = useContext(AuthContext);

  const menuRef = useRef(null);

  const [menuVisivel, setMenuVisivel] = useState(false);

  async function handleDeslogar() {
    logout();
  }

  const mostrarMenu = () => {
    setMenuVisivel(!menuVisivel);
  };

  const fecharMenu = () => {
    setMenuVisivel(false);
  };

  const fecharMenuComEscape = (event) => {
    if (event.key === "Escape") {
      fecharMenu();
    }
  };

  const fecharMenuForaDoClique = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      fecharMenu();
    }
  };

  const alterarSenha = () => {
    fecharMenu();
  };

  useEffect(() => {
    document.addEventListener("keydown", fecharMenuComEscape);
    document.addEventListener("mousedown", fecharMenuForaDoClique);

    return () => {
      document.removeEventListener("keydown", fecharMenuComEscape);
      document.removeEventListener("mousedown", fecharMenuForaDoClique);
    };
  }, []);

  return (
    <div className="title">
      <div className="title_info">
        {children}
        <span>{descricao}</span>
      </div>
      <div onClick={mostrarMenu}>
        <img src={avatar} alt="Foto de perfil" width={40} height={40} />
      </div>

      {menuVisivel && (
        <div ref={menuRef} className="menu">
          <Link
            to="/app/change-pass"
            className="menu_item"
            onClick={alterarSenha}
          >
            <MdPassword />
            <span className="menu_text">Alterar Senha</span>
          </Link>
          <div className="menu_item" onClick={handleDeslogar}>
            <MdLogout />
            <span className="menu_text">Deslogar</span>
          </div>
        </div>
      )}
    </div>
  );
}
