import { Route, Routes } from "react-router-dom";

import CadastrarNovaSenha from "../pages/CadastrarNovaSenha";
import Login from "../pages/Login";
import NotFoundPage from "../pages/NotFoundPage";
import SolicitarNovaSenha from "../pages/SolicitarNovaSenha";

function AuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/app/newpass" element={<SolicitarNovaSenha />} />
      <Route path="/app/changepass/:token" element={<CadastrarNovaSenha />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AuthRoutes;
