import React, { useEffect, useState } from "react";
import CmxDate from "../ui/DateTime";
import MultiSelect from "../ui/MultiSelect";
import "./style.css";

const Filtros = ({
  filters,
  setFilters,
  tiposChamado,
  situacoesChamado,
  areas,
}) => {
  const [abertoDe, setAbertoDe] = useState("");
  const [abertoAte, setAbertoAte] = useState("");

  useEffect(() => {
    // Carregar filtros do localStorage quando o componente é montado
    const savedFilters = JSON.parse(localStorage.getItem("cmx_filters"));
    if (savedFilters) {
      setFilters(savedFilters);
      setAbertoDe(
        savedFilters.abertoDe !== "01/07/2010" ? savedFilters.abertoDe : ""
      );
      setAbertoAte(
        savedFilters.abertoAte !== "31/12/2100" ? savedFilters.abertoAte : ""
      );
    }
  }, [setFilters]);

  useEffect(() => {
    // Salvar filtros no localStorage quando os filtros são atualizados
    localStorage.setItem("cmx_filters", JSON.stringify(filters));
  }, [filters]);

  const handleSetFilter = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleDateChange = (name, value) => {
    handleSetFilter(name, value);
    if (name === "abertoDe") {
      setAbertoDe(value);
    } else if (name === "abertoAte") {
      setAbertoAte(value);
    }
  };

  return (
    <div className="row filtro" style={{}}>
      <div className="col col-2">
        <label className="titulo">Aberto de</label>
        <CmxDate
          placeholder={"Início"}
          inputValue={abertoDe}
          setInputValue={(value) => handleDateChange("abertoDe", value)}
          type="text"
        />
      </div>
      <div className="col col-2">
        <label className="titulo">Aberto até</label>
        <CmxDate
          placeholder={"Fim"}
          inputValue={abertoAte}
          setInputValue={(value) => handleDateChange("abertoAte", value)}
          type="text"
        />
      </div>
      <div className="col col-3">
        <label className="titulo">Situação Chamado</label>
        <MultiSelect
          options={situacoesChamado}
          selectedOptions={filters.situacaoChamado}
          setSelectedOptions={(value) =>
            handleSetFilter("situacaoChamado", value)
          }
          label="Situação Chamado"
          uniqueId={"situacaoChamado"}
        />
      </div>
      <div className="col col-2">
        <label className="titulo">Tipo Chamado</label>
        <MultiSelect
          options={tiposChamado}
          selectedOptions={filters.tipoChamado}
          setSelectedOptions={(value) => handleSetFilter("tipoChamado", value)}
          label="Tipo Chamado"
          uniqueId={"tipoChamado"}
        />
      </div>
      <div className="col col-3">
        <label className="titulo">Área</label>
        <MultiSelect
          options={areas}
          selectedOptions={filters.area}
          setSelectedOptions={(value) => handleSetFilter("area", value)}
          label="Área"
          uniqueId={"area"}
        />
      </div>
    </div>
  );
};

export default Filtros;
