import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";

export default function NotFoundPage() {
  const [errorClass, setErrorClass] = useState("container_error");
  const navigate = useNavigate();

  useEffect(() => {
    setErrorClass("container_error container_error_active");
  }, []);

  async function handleToLogin(e) {
    e.preventDefault();
    navigate("/");
  }

  return (
    <div className="container_principal">
      <div className={errorClass}>
        <h1>404 - Página Não Encontrada</h1>
        <p>Ops página não encontrada.</p>
        <button onClick={handleToLogin}>Voltar</button>
      </div>
      <div className="aura_1"></div>
      <div className="aura_2"></div>
    </div>
  );
}
